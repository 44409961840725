



































































































































































































import { Component, Vue } from "vue-property-decorator";
import chinaDataJson from "@/assets/datajson/china.json";
import { Form } from 'element-ui'
import {
  listUserAddress,
  insertUserAddress,
  updateUserAddress,
  delUserAddress,
  getUserInfo,
} from "@/api";

interface IOrder {
  id: number;
  orderId: string;
  buyingTime: string;
  model: string;
  factory: string;
  amount: number;
  price: string;
  total: string;
}

interface IAddress {
    id: string,
    mobile: string,
    linkMan: string,
    address: string,
    remark: string,
    isMain: string,
    userId:string,
    phone:string,
    customerId:string,
    email:string,
    qq:string,
    cascaderVal :Array<number>
}

@Component
export default class UserInfo extends Vue {
  currentPage = 1;
  pageSize = 5;
  addressList: Array<IAddress> = [];
  dialogFormVisible = false;

//mobile手机，phone座机
  address : IAddress ={
     id: "",
    mobile: "",
    linkMan: "",
    address: "",
    remark: "",
    isMain: "",
    userId:"",
    phone:"",
    customerId:"",
    email:"",
    qq:"",
    cascaderVal :[]
  };
  userInfo = {
    isVip : 0,
    companyId : "0",
    userId : 0,
  };
  chinaData = chinaDataJson;
  rules = {
      linkMan: [{ required: true, message: '请输入收货人姓名', trigger: 'blur' }],
      mobile: [
        { required: true, message: '请输入手机号', trigger: 'blur' },
        { validator: this.validateMobile, trigger: 'blur' },
      ],
      address: [{ required: true, message: '请输入收货地址', trigger: 'blur' }],
      cascaderVal: [{ type:'array', required: true, message: '请选择省市区', trigger: 'change' }],
      email: [
        { required: true, message: '请输入邮箱地址', trigger: 'blur' },
        {
          type: 'email',
          message: '请输入正确的邮箱地址',
          trigger: 'blur',
        },
      ],
    }
  mounted(): void {
    // this.fetchOrderList();
    this.getUserInfo();
    // this.listUserAddress();
    // console.log(chinaDataJson);
  }
   // eslint-disable-next-line
  validateMobile(rule: any, value: string, callback: any): void {
    if (!/^[1][3,4,5,7,8][0-9]{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'))
    } else {
      callback()
    }
  }
getUserInfo(): void{
  getUserInfo({}).then(res => {
    if(res.status == 200){
        if(res.data.code == 200){
          this.userInfo = res.data.user
          window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          this.listUserAddress();
        }else{
          this.$message.error(res.data.msg );
        }
      }else{
        this.$message.error(res.data.statusText);
      } 
    }).catch(() => {
      console.log(' error ')
    })
};
  tableRowClassName(row:any)   {
    // console.log(row ,rowIndex,"=====");
        if (row.row.isMain == "1") {
          return 'success-row';
        } 
         return '';
      };
   validateForm(): void {
    ;(this.$refs['addressForm'] as Form).validate((valid: boolean) => {
      if (valid) {
        // this.submitAddress()
        this.insertOrUpdate()
      } else {
        console.log('error submit!!')
        return false
      }
    })
  }

  showDialog(isUpdate:any,index:any,row:any): void{
    this.dialogFormVisible = true;
    // console.log(isUpdate,"==",index,"===",row)
    if(isUpdate == "true"){

      this.address.id = row.id;
      this.address.linkMan = row.linkMan;
      this.address.mobile = row.mobile;
      this.address.isMain = row.isMain;
      this.address.address = row.address;
      
      this.address.customerId = row.customerId;
      this.address.qq = row.qq;
      this.address.phone = row.phone;
      this.address.email = row.email;
      this.address.cascaderVal = row.cascaderVal;
      // console.log(this.address)
    }else{
      this.address.id = "";
      this.address.linkMan = "";
      this.address.mobile = "";
      this.address.isMain = "0";
      this.address.address = "";
      this.address.qq = "";
      this.address.email = "";
      this.address.phone = "";
      this.address.customerId = this.userInfo.companyId;
      this.address.cascaderVal = [];

      
    }
  };
  insertOrUpdate():void {
    if(this.address.id){
      this.updateUserAddress();
    }else{
      this.insertUserAddress();
    }
  }
  // fetchOrderList(): void {
  //   for (let i = 1; i < 10; i++) {
  //     this.addressList.push({
  //       id: i,
  //       orderId: "2016102111523538",
  //       buyingTime: "美丽王小姐",
  //       model: "13612541188",
  //       factory: "广东省深圳市福田区报业大厦1楼星载电子有限公司",
  //       amount: 10,
  //       price: "¥16000",
  //       total: "¥20123",
  //     });
  //   }
  // }
  //地址列表
  listUserAddress(): void {
  
    listUserAddress()
      .then((res) => {
        if (res.status == 200) {
          if (res.data.code == 200) {
            
            this.addressList = res.data.data;
            // for(let i = 0 , len = this.addressList.length ;i<len;i++){
            //   let item = this.addressList[i];
            
            // }
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //新增
  insertUserAddress(): void {
    this.address.id = "";
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      mobile: this.address.mobile,
      
      address: this.address.address,
      remark: this.address.remark,
      isMain: this.address.isMain,
      userId: userInfo.userId,
      customerId : this.userInfo.companyId,
      linkMan : this.address.linkMan,  
      phone : this.address.phone,
      qq:this.address.qq,
      email:this.address.email,
      cascaderVal:this.address.cascaderVal.toString()
    };
    insertUserAddress(data)
      .then((res) => {
        // console.log(res)
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res)
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
            this.$message.success("添加成功");
            this.dialogFormVisible = false;
            this.listUserAddress();
          } else {
            this.$message.warning(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        this.$message.error("服务器出了点小问题,工程师正在玩命抢修中");
      });
  }
  //修改
  updateUserAddress(): void {
    // this.address.id = row.id;
    // this.address.name = row.name;
    // this.address.mobile = row.mobile;
    // this.address.isMain = row.isMain;
    // this.address.address = row.address;
     
    let userInfo = JSON.parse(window.localStorage.getItem("userInfo"));
    let data = {
      mobile: this.address.mobile,
      linkMan: this.address.linkMan,
      address: this.address.address,
      remark: this.address.remark,
      isMain: this.address.isMain,
      userId: userInfo.userId,
      id: this.address.id,
      customerId : this.userInfo.companyId,
      phone : this.address.phone,
      qq:this.address.qq,
      email:this.address.email,
      cascaderVal:this.address.cascaderVal.toString()
    };
    
    updateUserAddress(data)
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          if (res.data.code == 200) {
            // console.log(res);
            this.$message.success("修改成功");
            this.dialogFormVisible = false;
            this.listUserAddress();
            // this.userInfo = res.data.user
            // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
          } else {
            this.$message.error(res.data.msg);
          }
        } else {
          this.$message.error(res.data.statusText);
        }
      })
      .catch(() => {
        console.log(" error ");
      });
  }
  //删除
  delUserAddress(index: any, row: any): void {
    // console.log(index, "==========", row);
    this.$confirm("删除后不可恢复,确定对该地址进行删除操作吗 ?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        ///////删除请求
          delUserAddress(row.id)
            .then((res) => {
              
              if (res.status == 200) {
                if (res.data.code == 200) {
                  this.$message.success("删除成功");
                  this.listUserAddress();
                  // this.userInfo = res.data.user
                  // window.localStorage.setItem("userInfo",JSON.stringify(res.data.user));
                } else {
                  this.$message.error(res.data.msg);
                }
              } else {
                this.$message.error(res.data.statusText);
              }
            })
            .catch(() => {
              console.log(" error ");
            });
      })
      ///////删除请求
      .catch(() => {console.log(" error ");});

 
  }

  checkTransportation(a: number, b: IOrder): void {
    console.log(a, b);
  }

  applyInvoice(a: number, b: IOrder): void {
    console.log(a, b);
  }

  changeProduct(a: number, b: IOrder): void {
    console.log(a, b);
  }

  handleSizeChange(val: number): void {
    this.pageSize = val;
    this.currentPage = 1;
  }

  handleCurrentChange(val: number): void {
    this.currentPage = val;
  }
  handleCascaderChange(value: Array<string>) : void{
        console.log(value);
  }
      
}
